<script setup lang="ts">
import type { MainStepsProps } from "~/components/types/props.types";

const props = withDefaults(defineProps<MainStepsProps>(), {
  stepsTitle: "Как получить заём в офисе Быстроденьги?",
  stepsElements: () => [
    {
      name: "Оформите заявку",
      description: "Для оформления займа нужен только паспорт гражданина РФ",
      image: "/images/step1.png",
      tags: ["Не более 1 минуты"],
    },
    {
      name: "Получите решение",
      description: "Уведомление с решением придёт по CМС на указанный номер ",
      image: "/images/step2.png",
      tags: ["Одобряем 99% заявок"],
    },
    {
      name: "Получите деньги",
      description: "Быстрая выдача наличных<br/>в офисе",
      image: "/images/step3.png",
      tags: ["Мгновенно"],
    },
  ],
});
</script>
<template>
  <div class="main-steps">
    <div class="container">
      <div class="main-steps__title" v-html="props.stepsTitle" />
      <div class="main-steps__items">
        <template v-for="(item, index) in props.stepsElements" :key="index">
          <div class="main-steps__item">
            <img class="main-steps__item-img" :src="item.image" />
            <div class="main-steps__item-name" v-text="item.name" />
            <div
              v-if="item.description"
              class="main-steps__item-text"
              v-html="item.description"
            />
            <div v-if="item.tags?.length" class="main-steps__item-tags">
              <div
                v-for="tag in item.tags"
                :key="tag"
                class="main-steps__item-tag"
                v-text="tag"
              ></div>
            </div>
          </div>
          <div v-if="index == 0" class="main-steps__arrow">
            <img src="/images/arrow_card_blue.svg" />
          </div>
          <div v-if="index == 1" class="main-steps__arrow">
            <img src="/images/arrow_card_blue.svg" />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "MainSteps";
</style>
